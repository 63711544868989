import { BlogPostView } from "./BlogPostView";
import "./index.scss";
import { graphql } from "gatsby";

export default BlogPostView;

export const query = graphql`
  query BlogPostRender($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      timeToRead
      ...ArticleSEOFragment
      frontmatter {
        title
        subtitle
        ...PostHeadingImageFragment
        ...PeopleFragment
        date {
          created(locale: "gb", formatString: "Do MMMM YYYY, h:mm:ss a")
          modified(locale: "gb", formatString: "Do MMMM YYYY, h:mm:ss a")
        }
      }
      fields {
        type
      }
    }
  }
`;
