import { BlogPostImage } from "../../components/BlogPostImage";
import { BlogPostSplit } from "../../components/BlogPostSplit";
import { PageContainer } from "../../components/PageContainer";
import { PageLayout } from "../../components/PageLayout";
import { PageSection } from "../../components/PageSection";
import { People } from "../../components/People";
import { SectionCallToAction } from "../../components/SectionCallToAction";
import { ArticleSEO } from "../../components/SiteSEO";
import { SocialMediaButtons } from "../../components/SocialMediaButtons";
import React from "react";

const BlogPostView = ({
  data,
  cmsOverrideImage,
  noseo = false,
}: {
  data: Queries.BlogPostRenderQuery;
  noseo?: boolean;
  cmsOverrideImage?: string;
}) => {
  const html = data?.markdownRemark?.html;

  return (
    <PageLayout noseo={noseo}>
      {data.markdownRemark && !noseo && (
        <ArticleSEO data={data.markdownRemark} section="Blog" />
      )}

      <PageSection>
        <PageContainer>
          <h1 className="text-5xl font-bold mt-12">
            {data?.markdownRemark?.frontmatter?.title}
          </h1>
          <BlogPostSplit
            topright={
              <div>
                <p className="pb-3 text-xl font-bold">Authors</p>
                <People people={data!.markdownRemark!.frontmatter!.people} />
                <p className="pb-3 pt-6 text-xl font-bold">Share</p>
                <SocialMediaButtons
                  pageTitle={data?.markdownRemark?.frontmatter?.title || ""}
                  slug={data.markdownRemark?.fields?.slug || undefined}
                />
                <p className="pb-3 pt-6 text-xl font-bold">About</p>
                {data.markdownRemark?.frontmatter?.date?.created && (
                  <p>
                    Page created:{" "}
                    {data.markdownRemark?.frontmatter?.date?.created}
                  </p>
                )}
                {data.markdownRemark?.frontmatter?.date?.modified && (
                  <p>
                    Last modified:{" "}
                    {data.markdownRemark?.frontmatter?.date?.modified}
                  </p>
                )}
                <p>About a {data.markdownRemark?.timeToRead} minute read</p>
              </div>
            }
            bottomright={null}
          >
            <BlogPostImage
              cmsOverrideImage={cmsOverrideImage}
              image={data?.markdownRemark?.frontmatter?.image}
            />
            <article
              className="blogpostarea"
              dangerouslySetInnerHTML={{ __html: html || "" }}
            />
          </BlogPostSplit>
        </PageContainer>
      </PageSection>
      <SectionCallToAction>
        Let goSystem empower you and your business with IT
      </SectionCallToAction>
    </PageLayout>
  );
};

export { BlogPostView };
