import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const BlogPostImage = ({
  image,
  cmsOverrideImage,
}: {
  image?: Queries.PostHeadingImageFragmentFragment["image"];
  cmsOverrideImage?: string;
}) => {
  return (
    <>
      {cmsOverrideImage && image && image.alt && (
        <figure className="flex flex-col by-6 w-full h-80">
          <img
            className="rounded-xl drop-shadow-2xl w-full h-full object-cover"
            alt={image.alt}
            title={image.subtitle || undefined}
            src={cmsOverrideImage}
          />
          {image.subtitle && (
            <figcaption className="text-center">{image.subtitle}</figcaption>
          )}
        </figure>
      )}
      {image?.file?.childImageSharp?.gatsbyImageData && image.alt && (
        <figure className="flex flex-col by-6 w-full h-80">
          <GatsbyImage
            className="rounded-xl drop-shadow-2xl"
            alt={image.alt}
            title={image.subtitle || undefined}
            image={image.file.childImageSharp?.gatsbyImageData}
          />
          {image.subtitle && (
            <figcaption className="text-center">{image.subtitle}</figcaption>
          )}
        </figure>
      )}
    </>
  );
};

export { BlogPostImage };
