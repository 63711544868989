// extracted by mini-css-extract-plugin
export var bottomRightClass = "index-module--bottomRightClass--ZUkkD";
export var childClass = "index-module--childClass--oXPpt";
export var childClass2xl = "index-module--childClass-2xl--CP7Ij";
export var childClassBase = "index-module--childClass-base--WFYO3";
export var childClassLg = "index-module--childClass-lg--7M1JH";
export var childClassSm = "index-module--childClass-sm--Nt6oU";
export var childClassXl = "index-module--childClass-xl--meJcQ";
export var parentClass = "index-module--parentClass--AYYgT";
export var prose = "index-module--prose--ETTFc";
export var topRightClass = "index-module--topRightClass--tJfyy";