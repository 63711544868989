import {
  parentClass,
  childClass,
  topRightClass,
  bottomRightClass,
} from "./index.module.scss";
import React, { ReactNode } from "react";

const BlogPostSplit = ({
  children,
  topright,
  bottomright,
}: {
  children: ReactNode;
  topright: ReactNode;
  bottomright: ReactNode;
}) => {
  return (
    <div className={parentClass}>
      <div className={topRightClass}>{topright}</div>
      <div className={childClass}>{children}</div>
      <div className={bottomRightClass}>{bottomright}</div>
    </div>
  );
};

export { BlogPostSplit };
