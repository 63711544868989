import {
  SiteButton,
  SiteButtonButItAttemptsToPreventItselfFromBeingBlocked,
  SiteButtonType,
} from "../SiteButton";
import React from "react";
import {
  FaFacebook,
  FaGooglePlusG,
  FaLinkedin,
  FaReddit,
  FaTwitter,
} from "react-icons/fa";

const website = process.env.CF_PAGES_URL || "https://gosystem.co.uk";

const SocialMediaButtons = ({
  pageTitle,
  slug,
}: {
  pageTitle: string;
  slug?: string;
}) => {
  const currentURL = `${website}${slug}`;

  const encodedURL = encodeURIComponent(currentURL);
  const encodedTweet = encodeURIComponent(`${pageTitle}\n${currentURL}`);

  return (
    <div className="flex flex-wrap gap-2">
      <SiteButtonButItAttemptsToPreventItselfFromBeingBlocked
        type={SiteButtonType.FACEBOOK}
        title="Facebook"
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodedTweet}`}
        target="_blank"
      >
        <FaFacebook />
      </SiteButtonButItAttemptsToPreventItselfFromBeingBlocked>

      <SiteButtonButItAttemptsToPreventItselfFromBeingBlocked
        type={SiteButtonType.TWITTER}
        title="Tweet on Twitter"
        href={`https://twitter.com/intent/tweet?text=${encodedTweet}`}
        target="_blank"
      >
        <FaTwitter />
      </SiteButtonButItAttemptsToPreventItselfFromBeingBlocked>

      <SiteButtonButItAttemptsToPreventItselfFromBeingBlocked
        type={SiteButtonType.LINKEDIN}
        title="Linkedin"
        href={`https://www.linkedin.com/cws/share?url=${encodedURL}`}
        target="_blank"
      >
        <FaLinkedin />
      </SiteButtonButItAttemptsToPreventItselfFromBeingBlocked>

      <SiteButtonButItAttemptsToPreventItselfFromBeingBlocked
        type={SiteButtonType.REDDIT}
        title="Reddit"
        href={`https://www.reddit.com/submit?url=${encodedURL}`}
        target="_blank"
      >
        <FaReddit />
      </SiteButtonButItAttemptsToPreventItselfFromBeingBlocked>

      <SiteButton
        type={SiteButtonType.GOOGLEPLUS}
        title="Google Plus for Gsuite"
        href={`https://currents.google.com/share?url=${encodedURL}`}
        target="_blank"
      >
        <FaGooglePlusG />
      </SiteButton>
    </div>
  );
};

export { SocialMediaButtons };
