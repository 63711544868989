import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const People = (data?: Queries.PeopleFragmentFragment) => {
  return (
    <div className="flex flex-col gap-5">
      {data?.people?.map((person) => (
        <div key={person?.person?.id} className="flex items-center gap-2">
          {person?.person?.frontmatter?.image?.file?.childImageSharp
            ?.gatsbyImageData && (
            <GatsbyImage
              className="rounded-2xl w-12 h-12 drop-shadow bg-white"
              alt={`An image of ${person.person.frontmatter.title}`}
              title={person.person.frontmatter.subtitle || ""}
              image={
                person.person.frontmatter.image.file?.childImageSharp
                  .gatsbyImageData
              }
            />
          )}
          <div className="leading-5">
            <p>{person?.person?.frontmatter?.title}</p>
            <p className="italic text-gray-500">
              {person?.person?.frontmatter?.subtitle}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export { People };
